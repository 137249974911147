<template>
  <div>
    <!-- <div class="row mb-2">
          <div class="col-12 detais-title">
              <button data-toggle="modal" data-target="#addNewDealerModal" class="rem-btn pull-right"><img class="img-fluid" src="../../../assets/img/23. Close.svg" alt=""> Add Dealer</button>
          </div>
      </div> -->
    <div class="leads-table-area dealers-table">
      <table class="display" id="dealers_table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <!-- <th>Twillio Phone</th> -->
            <th>Crm Email</th>
            <!-- <th>Address</th>
              <th>Status</th> -->
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>

    <!-- Add Deaker Modal -->
    <!-- <div class="modal fade" id="addNewDealerModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title">Add Dealer</h5>
                      <a class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img class="img-fluid" src="../../../assets/img/23. Closex.svg" alt=""></span>
                      </a>
                  </div>
                  <form v-on:submit.prevent = "addNewDealer" autocomplete="off">
                  <div class="modal-body">
                      <div class="add-member add-lead">
                          <div class="form-row">
                              <div class="form-group col-md-6">
                                  <label>Name</label>
                                  <input type="text" class="form-control" v-model="dealer.dl_name" autocomplete="stop">
                              </div>
                              <div class="form-group col-md-6">
                                  <label>Phone</label>
                                  <input type="tel" class="form-control" v-model="dealer.dl_phone"  autocomplete="stop">
                              </div>
                          </div>
                          <div class="form-group">
                              <label>Email</label>
                              <input type="email" class="form-control" v-model="dealer.dl_email " autocomplete="stop">
                          </div>
                          <div class="form-group">
                              <label>Address</label>
                              <textarea class="form-control"  v-model="dealer.dl_address"></textarea>
                          </div>
                          <div class="form-group">
                              <label>Crm Email</label>
                              <input type="email" class="form-control" v-model="dealer.dl_crm_email " autocomplete="stop">
                          </div>
                          <div class="form-group">
                              <label>Twillio number</label>
                              <input type="tel" class="form-control"  v-model="dealer.dl_twilio_phone" >
                          </div>
                            
                      </div>
                  </div>
                  <div class="modal-footer">
                      <button :disabled="btnLoader" type="submit" class="rem-btn"><span v-if="btnLoader"><span class="spinner-grow spinner-grow-sm"></span> Loading...</span> <span v-if="!btnLoader"><img class="img-fluid" src="../../../assets/img/23. Close.svg" alt=""> Add</span></button>
                  </div>
                  </form>
              </div>
          </div>
      </div> -->
    <!-- Add Dealers Modal -->
    <div
      class="modal fade"
      id="importmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sourceBudgetModelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="sourceBudgetModelLabel">
              Import Customers
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="importCsv" enctype="multipart/form-data">
            <div class="modal-body">
              <div class="add-member">
                <div class="form-group">
                  <label>CRM</label>
                  <select class="form-control" v-model="crm">
                    <option value="">Select Crm</option>
                    <option
                      v-for="crm in thirdpartycrm"
                      :value="crm.crm_name"
                      :key="crm.id"
                    >
                      {{ crm.crm_name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Import CSV</label>
                  <input
                    type="file"
                    class="form-control"
                    v-on:change="onFileChange"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"> Cancel</a>
              <!-- <button type="submit" class="rem-btn" > Okay</button> -->
              <button type="submit" class="rem-btn">
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"
                  ><img
                    class="img-fluid"
                    src="../../../assets/img/23. Close.svg"
                    alt=""
                  />
                  Import</span
                >
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

export default {
  name: "Dealers",
  data() {
    return {
      dealer_id: "",
      crm: "",
      thirdpartycrm: [],
      file: "",
      allDealres: [],
      btnLoader: false,
      dealer: {},
    };
  },
  created() {
    // this.$root.$on('action', function(action) {
    //     if(action == 'addDealerModal'){
    //         $("#addNewDealerModal").modal('show')
    //     }
    // })
    this.getthirdparty();
  },
  methods: {
    importCsv() {
      let self = this;
      self.btnLoader = true;
      const config = {
        headers: {
          Authorization: "Bearer " + self.$storage.get("auth").token,
        },
      };

      let formData = new FormData();
      formData.append("file", self.file);
      formData.append("crm", self.crm);
      formData.append("dealer_id", self.dealer_id);

      axios
        .post("/importCSV", formData, config)
        .then(function (response) {
          if (response.status) {
            self.btnLoader = false;
            $("#importmodal").modal("hide");
            alert(response.data.message);
          } else {
            alert("Something Wrong");
          }
        })
        .catch(function (error) {
          alert("Something Wrong");
          self.output = error;
          self.btnLoader = false;
        });
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
    },
    getthirdparty() {
      axios
        .get("thirdparty/crm", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.thirdpartycrm = res.data.success.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // addNewDealer(){
    //     this.btnLoader = true
    //     axios
    //     .post('admin/add_new_dealer',this.dealer,{headers: {Authorization:'Bearer '+this.$storage.get('auth').token}})
    //     .then( res => {
    //       console.log(res)
    //         if(res.data.success){
    //             $("#addNewDealerModal").modal('hide')
    //             this.dealer={}
    //             $('#dealers_table').dataTable().fnDraw();
    //         }
    //         this.btnLoader = false
    //     })
    //     .catch(err =>{
    //         this.btnLoader = false
    //         console.log(err)
    //     })
    // }
  },
  mounted() {
    $("#dealers_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        order: [[0, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "admin/all_dealers",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        columns: [
          {
            data: "dl_name",
          },
          {
            data: "dl_email",
          },
          {
            data: "dl_phone",
          },
          {
            data: "dl_crm_email",
          },
          //   {
          //     data: "dl_address",
          //   },
          //   {
          //     data: "dl_status",
          //   },
          {
            data: "dl_created",
          },
          {
            data: "action",
          },
        ],
      });
    let vm = this;
    $("tbody").on("click", ".import", function () {
      var id = $(this).data("id");
      vm.dealer_id = id;
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        $("#importmodal").modal("show");
      }
    });

    //import lead data

    $("tbody").on("click", ".importleads", function () {
      var id = $(this).data("id");
      vm.dealer_id = id;
      axios
        .get("export/lead/data/" + vm.dealer_id, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "leadsheet.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    });
  },
};
</script>

<style scoped>
@import "../../../assets/css/dataTables.css";
</style>